<template>
  <div>
    <h2 class="text-size-h1 text-bold mb-m">{{ $metaInfo.title }}</h2>

    <div class="row">
      <!-- расписание у джуниоров и студентов только по первому этапу -->
      <div
        v-if="!isJunior && !isStudent"
        class="col-md-6 col-sm-6 col-xs-12 mb-s">
        <StageTabs v-model="stage" />
      </div>
      <div
        v-if="activities.length"
        class="col-md-6 col-sm-6 schedule-filter-wrapper mb-l"
        :class="{ 'text-right': !isJunior && !isStudent }">
        <div class="schedule-filter">
          <button
            class="schedule-filter__item"
            :class="{ 'is-active': !status }"
            @click="status = null">
            Все
          </button>
          <button
            class="schedule-filter__item"
            :class="{ 'is-active': status === 'upcoming' }"
            @click="status = 'upcoming'">
            Предстоящие
          </button>
          <button
            :class="{ 'is-active': status === 'past' }"
            class="schedule-filter__item"
            @click="status = 'past'">
            Прошедшие
          </button>
        </div>
      </div>
    </div>

    <div>
      <div v-if="activities.length">
        <ScheduleGrid :activities="activities" />
      </div>
      <div v-else>
        Пока нет точного расписания заданий. Как только оно появится, вы сможете
        увидеть его здесь
      </div>
    </div>
  </div>
</template>

<script>
import ScheduleGrid from "@/components/schedule/ScheduleGrid.vue";
import { mapGetters } from "vuex";
import { excludeWrongAttempts } from "@/utils/profile";
import dayjs from "@/plugins/dayjs";
import StageTabs from "@/components/StageTabs.vue";
export default {
  name: "UserSchedule",
  components: {
    ScheduleGrid,
    StageTabs,
  },
  metaInfo() {
    return {
      title: "Расписание заданий",
    };
  },
  data() {
    return {
      stage: 1,
      status: null,
    };
  },
  computed: {
    ...mapGetters({
      isJunior: "participant/isJunior",
      isStudent: "participant/isStudent",
      firstStage: "participant/firstStage",
      secondStage: "participant/secondStage",
    }),
    activities() {
      const { stage } = this;
      let currentStage;
      if (stage === 1) {
        currentStage = this.firstStage.stage;
      } else if (stage === 2) {
        currentStage = this.secondStage.stage;
      }
      const list =
        this.$store.getters["participant/activitiesByStage"](currentStage);
      return list.map((activity) => {
        let currentAttempts = excludeWrongAttempts(activity.attempts);
        // если активность внешняя и без попыток
        // то нужно создать попытку на основе активности
        if (activity.external_url && !activity.attempts?.length) {
          currentAttempts = [
            {
              start_at: activity.start_at,
              end_at: activity.end_at,
              title: activity.title,
              activity_id: activity.id,
              id: activity.id,
            },
          ];
        }
        if (this.status === "upcoming") {
          currentAttempts = currentAttempts.filter((attempt) => {
            return +dayjs(attempt.start_at) > Date.now();
          });
        } else if (this.status === "past") {
          currentAttempts = currentAttempts.filter((attempt) => {
            return +dayjs(attempt.end_at) < Date.now();
          });
        }
        return {
          ...activity,
          attempts: currentAttempts,
        };
      });
    },
  },
  mounted() {
    this.$store.dispatch("participant/getScores").catch((error) => {
      console.log("error", error);
    });
  },
};
</script>

<style lang="less" scoped>
.schedule-filter {
  display: inline-flex;
  flex-flow: row nowrap;
  background-color: #fff;
  box-shadow: @shadow-default;
  border-radius: @radius-s;
  overflow: hidden;

  &__item {
    box-shadow: none;
    border: 0;
    background-color: transparent;
    font-family: inherit;
    color: @link-color;
    padding: 8px 16px;
    cursor: pointer;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    transition: border 0.3s;
    outline: none;
    text-align: center;

    &:hover,
    &:focus {
      border-bottom-color: fade(@link-color, 20%);
    }

    &.is-active {
      border-bottom-color: @link-color !important;
    }
  }

  &-wrapper {
    // text-align: right;

    .media-only-xs({
      text-align: left !important;
    });
  }
}
</style>
